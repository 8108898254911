<template>
    <div>
        消息推送
    </div>
</template>

<script>
    export default {
        name: "index",
        data(){
            return{

            }
        }
    }
</script>

<style scoped>

</style>
